.App {
  text-align: center;
}

.App-header {
  background-color: #DFBC61;
  padding: 2px;
  color: #2D2A3D;
}

.App-logo {
  height: 200px; /* Adjust the height as needed */
  border-radius: 10px; /* Add border-radius for a rounded look */
  margin-bottom: 20px;
}


.Body-header {
  background-color: white;
  padding: 2px;
  color: #2D2A3D;
}
.App {
  text-align: center;
}
.image-gallery-slide img {
  border-radius: 10px; /* Add border-radius for a rounded look */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);




}
